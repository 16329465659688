<template>
  <div class="oao-navi-navigation">
    <div class="oao-navi-left">
      <div class="oao-navi-application-name">
        <a class="oao-navi-app-name" href="#">
          <span class="oao-navi-app-logo"></span>
          <span>
            <template v-if="loggedIn">
              {{ menuLabel }}
            </template>
            <template v-else>
              {{ applicationName }}
            </template>
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IonosGlobalNavigation',
  props: {
    loggedIn: {
      type: Boolean,
      default: false,
    },
    market: {
      type: String,
      default: '',
    },
    applicationName: String,
    frontendToken: String,
    frontendServicesIntegrationScript: String,
    logoutUrl: String,
    loginUrl: String,
  },
  data() {
    return {
      locale: '',
    };
  },
  computed: {
    menuLabel() {
      let menuLabel = 'Menu';
      if (this.locale) {
        //set translation of "menu"
        const firstPartOfLocale = this.locale.substring(0, this.locale.indexOf('-'));
        switch (firstPartOfLocale) {
          case 'de':
            menuLabel = 'Menü';
            break;
          case 'es':
            menuLabel = 'Menú';
            break;
          case 'fr':
          case 'it':
          case 'en':
            menuLabel = 'Menu';
        }
      }
      return menuLabel;
    },
  },
  watch: {
    frontendToken: {
      immediate: true,
      handler(newToken) {
        window.OAO.q.c.push(['setFrontendToken', newToken]);
      },
    },
    market: {
      immediate: true,
      handler(newMarket) {
        window.OAO.q.c.push(['setMarket', newMarket === 'GB' ? 'UK' : newMarket]);
        this.locale = this.getLocale(newMarket);
        window.OAO.q.c.push(['setLanguage', this.locale]);
        window.OAO.q.c.push(['setTenant', this.getTenant(newMarket)]);
      },
    },
  },
  beforeCreate() {
    window.OAO = window.OAO || {};
    window.OAO.q = window.OAO.q || {};
    window.OAO.q.c = window.OAO.q.c || [];
    window.OAO.q.navigation = window.OAO.q.navigation || [];
    window.OAO.q.pageintegration = window.OAO.q.pageintegration || [];
  },
  created() {
    window.OAO.q.navigation.push(['setAppName', this.applicationName]);
    window.OAO.q.navigation.push(['setLogoutUrl', this.logoutUrl]);
    window.OAO.q.navigation.push(['setLoginUrl', this.loginUrl]);
    // We do not "setPageName" as this will be determined from the GlobalNavigation if not set and is only for reporting/tracking
    this.addScriptToDocument();
  },
  methods: {
    addScriptToDocument() {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = this.frontendServicesIntegrationScript;
      script.async = true;
      script.defer = true;
      script.id = 'oaotag';

      document.head.appendChild(script);
    },
    getLocale(market) {
      switch (market) {
        case 'DE':
          return 'de_DE';
        case 'US':
          return 'en_US';
        case 'CA':
          return 'en_CA';
        case 'UK':
        case 'GB':
          return 'en_GB';
        case 'ES':
          return 'es_ES';
        case 'MX':
          return 'es_MX';
        case 'FR':
          return 'fr_FR';
        case 'IT':
          return 'it_IT';
        default:
          return 'en_US';
      }
    },
    getTenant(market) {
      if (!market) {
        return '';
      } else if (market === 'GB') {
        return 'IONOS_UK';
      } else {
        return `IONOS_${market}`;
      }
    },
  },
};
</script>

<style lang="scss">
// shift vuetify content if ciso flyin is visible
.oao-pi-flyin .v-content {
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    padding-right: 342px !important;
  }
}
</style>

import VueI18n from 'vue-i18n';
import { VueConstructor } from 'vue';
import { mergeLocales } from './locales';
import * as IonosComponents from './components';
import * as Plugin from './plugin';

export { ionosVuetifyTheme } from './theme';
export * from './models';
export * from './mixins';

export interface InstallOptions {
  i18n?: VueI18n;
}

const Components: { [key: string]: { name: string } } = {
  ...IonosComponents,
};

export { Plugin, Components };

export default {
  install(Vue: VueConstructor, options?: InstallOptions): void {
    options?.i18n && mergeLocales(options?.i18n);

    Object.keys(Components).forEach((name) => {
      Vue.component(name, Components[name]);
    });
  },
};

import VueI18n from 'vue-i18n';
import { VueConstructor } from 'vue';
import { mergeLocales } from './locales';
const componentsContext = require.context('./components', false, /\.vue$/);

export { ionosVuetifyTheme } from './theme';

function pathToName(path: string): string | null {
  const match = path.match(/^\.\/(.*\/)*(.+)\.vue$/);
  return match ? match[2] : null;
}

function componentNames(context: { keys(): string[] }, components: string[] | boolean) {
  let names: string[] = [];
  if (components === true) {
    names = <string[]>context
      .keys()
      .map(pathToName)
      .filter((name) => !!name);
  } else if (Array.isArray(components)) {
    names = components;
  }
  return names;
}

function register(Vue: VueConstructor, path: string, components: string[] | boolean) {
  if (Array.isArray(components)) {
    for (const name of components) {
      const globalName = name.startsWith('Ionos') ? name : `Ionos${name}`;
      Vue.component(globalName, () => import(`./${path}/${name}.vue`));
    }
  }
}

export interface PluginInstallOptions {
  i18n?: VueI18n;
  components?: string[] | boolean;
  icons?: string[] | boolean;
}

function install(Vue: VueConstructor, options?: PluginInstallOptions): void {
  const opts = {
    components: true,
    icons: true,
    ...options,
  };
  const { i18n } = opts;

  mergeLocales(i18n);
  register(Vue, 'components', componentNames(componentsContext, opts.components));
}

export default { install };

import Vue from 'vue';
import { Path, TranslateResult, Values } from 'vue-i18n';
import { LOCALE_PREFIX_DEFAULT } from './locales';

import mixins from 'vuetify/src/util/mixins';

export { mixins };

export const Translatable = Vue.extend({
  name: 'Translatable',
  methods: {
    $_t(path: Path, values?: Values): TranslateResult {
      return this.$t(`${LOCALE_PREFIX_DEFAULT}.${path}`, values);
    },
    $_te(path: Path): boolean {
      return this.$te(`${LOCALE_PREFIX_DEFAULT}.${path}`);
    },
  },
});

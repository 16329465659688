(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vuetify/lib"), require("vuetify/src/util/mixins"), require("vue"), require("vee-validate"));
	else if(typeof define === 'function' && define.amd)
		define(["vuetify/lib", "vuetify/src/util/mixins", "vue", "vee-validate"], factory);
	else if(typeof exports === 'object')
		exports["ionos-vuetify"] = factory(require("vuetify/lib"), require("vuetify/src/util/mixins"), require("vue"), require("vee-validate"));
	else
		root["ionos-vuetify"] = factory(root["vuetify/lib"], root["vuetify/src/util/mixins"], root["vue"], root["vee-validate"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__4875__, __WEBPACK_EXTERNAL_MODULE__6765__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE__9718__) {
return 
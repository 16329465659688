var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.colorClass,staticStyle:{"display":"inline-block"}},[_c('a',_vm._g({class:{
      disabled: _vm.disabled,
      loading: _vm.loading,
      wrap: _vm.wrap,
      blank: _vm.blank,
      'white--text': _vm.color === 'white',
      'error--text': _vm.color === 'error',
    },attrs:{"href":_vm.isLinkDisabled ? null : _vm.href,"target":_vm.isLinkDisabled ? null : _vm.target}},_vm.isLinkDisabled ? null : _vm.$listeners),[(_vm.icon)?_c('span',{class:_vm.iconClasses},[_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v(_vm._s(_vm.icon))])],1):_vm._e(),_vm._t("default"),(!_vm.loading)?_c('span',[(_vm.action)?_c('v-icon',{attrs:{"dense":""}},[_vm._v("arrow_forward")]):(_vm.external)?_c('v-icon',{staticClass:"d-inline-block vertical-align-unset",attrs:{"size":"small"}},[_vm._v("open_in_new")]):_vm._e()],1):_vm._e()],2),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"12","width":"1"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-alert :dark="dark" :outlined="!backgroundColor" v-bind="$attrs" :class="{ 'with-headline': !!headline }">
    <div v-if="headline" class="text-title-large mb-2">{{ headline }}</div>
    <div v-else class="text-body-medium"><slot /></div>
    <div v-if="headline" class="text-body-medium" :class="{ 'text--secondary': !dark && !backgroundColor }">
      <slot />
    </div>
    <template v-if="dismissible" #close>
      <v-icon aria-label="close" @click="$emit('close')">close</v-icon>
    </template>
  </v-alert>
</template>

<script>
import { VAlert, VIcon } from 'vuetify/lib';

export default {
  name: 'IonosAlert',
  components: {
    VAlert,
    VIcon,
  },
  props: {
    headline: String,
    backgroundColor: Boolean,
    dark: Boolean,
    dismissible: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.theme--light {
  .v-alert--outlined {
    background: $color-background !important;
  }
  .v-icon {
    color: $light-on-surface-subdued !important;
  }
}
.theme--dark {
  .v-alert--outlined {
    background: $dark-background !important;
  }
  .v-icon {
    color: $dark-on-surface-subdued !important;
  }
}

.text-title-large {
  line-height: 24px;
}

.v-alert {
  ::v-deep {
    .v-alert__content {
      color: map-get($material-light, 'text', 'primary') !important;
    }
  }
  &.with-headline ::v-deep {
    .v-alert__wrapper {
      align-items: start;
      .v-alert__dismissible {
        margin-top: -8px;
        margin-bottom: -8px;
      }
    }
  }
  &.theme--dark ::v-deep {
    .v-alert__content {
      color: map-get($material-dark, 'text', 'primary') !important;
    }
    .v-alert__wrapper .v-alert__dismissible i {
      color: $color-background !important;
    }
  }
  &.v-alert--text {
    border-width: 0 !important;
    padding: 0;
    &:before {
      background-color: transparent !important;
    }
  }
}
</style>

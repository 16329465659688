<template>
  <v-menu
    bottom
    offset-y
    close-on-click
    close-on-content-click
    content-class="navigation-menu"
    @input="onInput"
    transition="slide-y-transition"
    min-width="300"
  >
    <template #activator="{ on }">
      <v-btn
        text
        v-on="on"
        small
        tile
        :height="32"
        :width="smallScreen ? 32 : null"
        :icon="smallScreen"
        :class="{ 'pr-1 pl-2': !smallScreen }"
        class="white--text font-weight-regular"
      >
        <v-icon size="20">{{ icon }}</v-icon>
        <span v-if="!smallScreen" class="ml-1">{{ label }}</span>
        <v-icon v-if="!smallScreen">{{ opened ? 'expand_less' : 'expand_more' }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <slot />
    </v-card>
  </v-menu>
</template>

<script>
import { VMenu, VBtn, VIcon, VCard } from 'vuetify/lib';

export default {
  name: 'IonosMinimalNavigationMenu',
  components: {
    VMenu,
    VBtn,
    VIcon,
    VCard,
  },
  props: {
    label: String,
    icon: String,
  },
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    smallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    onInput(value) {
      this.opened = value;
    },
  },
};
</script>

<style lang="scss"></style>

<template>
  <v-hover v-slot="{ hover }">
    <v-chip
      :disabled="disabled"
      :close="closeable"
      :color="color"
      :outlined="outlined"
      :href="href"
      :target="target"
      :link="link"
    >
      <div :class="{ 'mr-2': closeable }" class="d-flex align-center">
        <slot />
        <v-expand-x-transition>
          <div v-if="hover" class="d-flex align-center">
            <slot class="ml-2" name="hover" />
            <a v-if="link" class="ml-2">
              <v-icon class="text--secondary" dense>open_in_new</v-icon>
            </a>
          </div>
        </v-expand-x-transition>
      </div>
    </v-chip>
  </v-hover>
</template>

<script>
import { VChip, VHover, VExpandXTransition, VIcon } from 'vuetify/lib';

export default {
  name: 'IonosChip',
  components: {
    VChip,
    VHover,
    VExpandXTransition,
    VIcon,
  },
  props: {
    primary: Boolean,
    outlined: Boolean,
    disabled: Boolean,
    closeable: Boolean,
    link: Boolean,
    href: String,
    target: String,
  },
  computed: {
    color() {
      if (this.primary) {
        return 'primary';
      }
      return '';
    },
  },
};
</script>

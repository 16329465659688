

























































































import { PropType } from 'vue';
import {
  VBreadcrumbs,
  VBreadcrumbsItem,
  VAppBar,
  VSpacer,
  VList,
  VListItem,
  VListItemContent,
  VIcon,
  VBtn,
  VDivider,
  VCardText,
  VContainer,
  VSystemBar,
} from 'vuetify/lib';
import IonosMinimalNavigationMenu from './MinimalNavigationMenu.vue';
import IonosButton from './Button.vue';
import { MenuItem, User } from '../models';
import { mixins, Translatable } from '../mixins';
import ToggleDarkMode from './ToggleDarkMode.vue';

interface BreadcrumbItem {
  text?: string;
  href?: string;
  disabled?: boolean;
  icon?: string;
}

export default mixins(Translatable).extend({
  name: 'IonosMinimalNavigation',
  components: {
    IonosMinimalNavigationMenu,
    IonosButton,
    ToggleDarkMode,
    VBreadcrumbs,
    VBreadcrumbsItem,
    VAppBar,
    VSpacer,
    VList,
    VListItem,
    VListItemContent,
    VIcon,
    VBtn,
    VDivider,
    VCardText,
    VContainer,
    VSystemBar,
  },
  props: {
    loggedIn: {
      type: Boolean,
      default: false,
    },
    applicationName: String,
    pageName: String,
    user: Object as PropType<User>,
    frontendToken: String,
    frontendServicesIntegrationScript: String,
    logoutUrl: String,
    loginUrl: String,
    helpMenuItems: Array as PropType<MenuItem[]>,
    homeRoute: Object,
    toggleDarkMode: {
      type: Boolean,
      default: false,
    },
    dark: Boolean,
  },
  data() {
    return {
      locale: '',
    };
  },
  computed: {
    userName(): string | undefined {
      return this.user?.name;
    },
    isWebProActor(): boolean | undefined {
      return this.user?.actor?.type === 'WEBPRO';
    },
    actorId(): string | undefined {
      return this.user?.actor?.id;
    },
    userOrganization(): string | undefined {
      return this.user?.organization;
    },
    breadcrumbItems(): BreadcrumbItem[] {
      return [
        {
          text: this.$vuetify.breakpoint.smAndUp ? this.$_t('minimalNavigation.home.label').toString() : undefined,
          href: this.$_t('minimalNavigation.home.url').toString(),
          icon: this.$vuetify.breakpoint.xsOnly ? 'home' : undefined,
        },
        {
          text: this.applicationName,
          ...(this.homeRoute ? { to: this.homeRoute } : { href: '/' }),
        },
        ...(this.pageName && this.$vuetify.breakpoint.smAndUp
          ? [
              {
                text: this.pageName,
                disabled: true,
              },
            ]
          : []),
      ];
    },
  },
  watch: {
    frontendToken: {
      immediate: true,
      handler(newToken) {
        window.OAO.q.c.push(['setFrontendToken', newToken]);
      },
    },
  },
  beforeCreate() {
    window.OAO = window.OAO || {};
    window.OAO.q = window.OAO.q || {};
    window.OAO.q.c = window.OAO.q.c || [];
    window.OAO.q.navigation = window.OAO.q.navigation || [];
    window.OAO.q.pageintegration = window.OAO.q.pageintegration || [];
  },
  mounted() {
    this.addScriptToDocument();
  },
  methods: {
    addScriptToDocument(): void {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = this.frontendServicesIntegrationScript;
      script.async = true;
      script.defer = true;
      script.id = 'oaotag';

      document.head.appendChild(script);
    },
  },
});

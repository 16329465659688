import { ThemeOptions } from 'vuetify/types/services/theme';

const ionosVuetifyTheme: ThemeOptions = {
  options: {
    customProperties: true,
  },
  themes: {
    light: {
      primary: '#0B2A63',
      primaryVariant: '#001B41',
      secondary: '#cad0d4',
      secondaryVariant: '#1474C4',
      accent: '#11C7E6',
      tertiary: '#11C7E6',
      error: '#C80A00',
      warning: '#EF8300',
      success: '#0FA954',
      info: '#1474C4',
      attention: '#D746F5',
      neutral: '#97A3B4',
      onTertiary: '#02102B',
    },
    dark: {
      primary: '#1D2D42',
      secondary: '#0B2A63',
      tertiary: '#08A5C5',
      success: '#0C8A44',
      warning: '#C36B00',
      error: '#9C0800',
      info: '#1474C4',
      attention: '#8212C2',
      onTertiary: '#02102B',
    },
  },
};

export { ionosVuetifyTheme };

<template>
  <v-navigation-drawer v-model="visible" app class="flyin" clipped hide-overlay right width="342">
    <div class="d-flex flex-column">
      <div>
        <v-row no-gutters row>
          <v-col class="header">
            <slot name="header" />
          </v-col>
          <v-col cols="auto">
            <div class="close-icon-container d-flex justify-center align-center" @click="close()">
              <v-icon>close</v-icon>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="content">
        <slot />
      </div>
      <div v-if="!!actionLabel" class="text-center">
        <ionos-button class="ma-3" primary @click="actionClicked()">{{ actionLabel }}</ionos-button>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { VCol, VNavigationDrawer, VRow, VIcon } from 'vuetify/lib';

export default {
  name: 'IonosFlyin',
  components: {
    VNavigationDrawer,
    VRow,
    VCol,
    VIcon,
  },
  props: {
    actionLabel: String,
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    visible(value) {
      if (!value) {
        this.close();
      }
    },
  },
  created() {
    this.visible = true;
  },
  methods: {
    close() {
      this.$emit('close');
    },
    actionClicked() {
      this.$emit('action');
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light {
  .close-icon-container {
    &:hover {
      background: $light-on-surface-subdued;
    }
  }
  .content {
    background: $color-background;
  }
}

.theme--dark {
  .close-icon-container {
    &:hover {
      background: $dark-on-surface-subdued;
    }
  }
  .content {
    background: $dark-background;
  }
}

.v-navigation-drawer {
  top: 64px !important;
  bottom: 0;
  height: initial !important;

  .d-flex {
    height: 100%;
  }
}

.oao-navi-actor-bar-visible .v-navigation-drawer {
  top: 93px;
}

.close-icon-container {
  width: 52px;
  height: 52px !important;
  cursor: pointer;

  &:hover {
    color: var(--v-primary-base);
    background: $color-gray-light;
  }
}

.header {
  margin: 1rem;
}

.content {
  height: 100%;
  padding: 1rem;
  overflow-y: scroll;
  background: $color-background;
}
</style>

<template>
  <div class="ionos-inline-switch">
    <v-switch
      v-bind="$attrs"
      v-on="$listeners"
      hide-details
      text
      inset
      small
      :class="{ 'without-label': withoutLabel }"
    />
  </div>
</template>

<script>
import { VSwitch } from 'vuetify/lib';

export default {
  name: 'IonosInlineSwitch',
  components: {
    VSwitch,
  },
  props: {
    withoutLabel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.ionos-inline-switch {
  position: relative;
}

.background-icon > * {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  height: 10px;
  margin: 0 4px;
  color: $color-white;
  pointer-events: none;
}

::v-deep .v-input--switch.v-input--switch--inset {
  padding: 0;
  margin: 0;

  &.without-label .v-input--selection-controls__input {
    margin-right: 0;
  }

  .v-input--switch__track,
  .v-input--selection-controls__input {
    width: 44px;
  }

  .v-input--switch__track {
    height: 24px;
    left: 0;
    top: calc(50% - 12px);

    &.theme--light {
      color: $light-surface;
      border: 2px solid $light-on-surface-subdued;
      &.primary--text {
        background: $light-on-surface-default !important;
        opacity: 1;
        border: none;
      }
    }

    &.theme--dark {
      color: $dark-surface;
      border: 2px solid $dark-on-surface-subdued;
      &.primary--text {
        background: $dark-on-surface-default !important;
        opacity: 1;
        border: none;
      }
    }
  }

  .v-input--switch__thumb {
    top: calc(50% - 9px);
    height: 18px;
    width: 18px;
    left: 3px;
    &.theme--light {
      color: $light-on-surface-subdued;
      &.primary--text {
        background: $color-white;
      }
    }
    &.theme--dark {
      color: $dark-on-surface-subdued;
      &.primary--text {
        background: $dark-surface;
      }
    }
  }
}
</style>

<template>
  <ionos-link button color="error" v-bind="$attrs" v-if="cancel" v-on="$listeners" icon="close">
    <slot />
  </ionos-link>
  <v-btn
    v-else
    :color="$attrs.color ? $attrs.color : color"
    :outlined="outlined || secondary"
    depressed
    rounded
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
import { VBtn } from 'vuetify/lib';

export default {
  name: 'IonosButton',
  props: {
    primary: Boolean,
    secondary: Boolean,
    tertiary: Boolean,
    outlined: Boolean,
    cancel: Boolean,
  },
  components: {
    VBtn,
  },
  computed: {
    color() {
      if (this.primary) {
        return 'primary';
      } else if (this.secondary) {
        return 'neutral';
      } else if (this.tertiary) {
        return 'tertiary';
      } else if (this.cancel) {
        return 'error';
      } else if (this.outlined) {
        return 'primaryVariant';
      }

      return '';
    },
  },
};
</script>

<style scoped></style>

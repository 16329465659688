<template>
  <div class="ionos-pagination">
    <v-row align="center" justify="space-between" no-gutters>
      <v-col
        v-if="itemCountText"
        :order="$vuetify.breakpoint.xsOnly ? 'last' : 'first'"
        class="text-center text-sm-left"
        cols="12"
        sm="auto"
      >
        {{ itemCountText }}
      </v-col>
      <v-col class="text-center text-sm-right" cols="12" sm="auto">
        <v-pagination
          v-if="pageCount > 1"
          :length="pageCount"
          :total-visible="5"
          :value="selectedPage + 1"
          current-page-aria-label="Current page"
          next-aria-label="Next"
          page-aria-label="Page"
          previous-aria-label="Previous"
          wrapper-aria-label="Wrapper"
          @input="selectPage($event - 1)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { VPagination, VCol, VRow } from 'vuetify/lib';

export default {
  name: 'IonosPagination',
  components: {
    VPagination,
    VCol,
    VRow,
  },
  props: {
    selectedPage: Number,
    pageCount: Number,
    itemCountText: String,
  },
  methods: {
    selectPage(page) {
      this.$emit('select-page', page);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-pagination__item,
::v-deep .v-pagination__navigation {
  box-shadow: none;
  background: none !important;

  // prevent border on focus
  &:focus {
    outline-width: 1px;
  }
}

.v-pagination {
  width: unset;
}
</style>

<template>
  <div class="root">
    <v-main>
      <div
        :class="{ 'page-section-secondary': darkBackground }"
        class="d-flex flex-column justify-space-between fill-height"
      >
        <div>
          <slot name="header" />
          <transition name="slide-fade">
            <slot name="body" />
          </transition>
        </div>
        <div>
          <slot name="footer" />
        </div>
      </div>
      <slot />
    </v-main>
  </div>
</template>

<script>
import { VMain } from 'vuetify/lib';

export default {
  name: 'IonosPageLayout',
  props: {
    darkBackground: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VMain,
  },
};
</script>

<style lang="scss" scoped>
.root {
  flex: inherit;
  flex-direction: inherit;
  display: inherit;
}

.v-content {
  overflow-x: hidden;
}

.slide-fade-enter-active {
  transition: all 0.4s 0.4s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.4s ease-in-out;
}

.slide-fade-leave-to {
  opacity: 0;
}

.slide-fade-enter {
  opacity: 0;
}
</style>

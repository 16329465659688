import VueI18n from 'vue-i18n';

const localeContext = require.context('./', false, /\.json$/);

export const LOCALE_PREFIX_DEFAULT = 'ionos-vuetify';

export function mergeLocales(i18n?: VueI18n): void {
  if (i18n) {
    for (const locale of i18n.availableLocales) {
      const filename = `./${locale}.json`;
      if (localeContext.keys().includes(filename)) {
        i18n.mergeLocaleMessage(locale, { [LOCALE_PREFIX_DEFAULT]: localeContext(filename) });
      }
    }
  }
}

<template>
  <div>
    <v-divider v-if="!transparentBackground" />
    <v-card-actions class="py-4 px-6" :class="{ 'background-primary': !transparentBackground }">
      <v-row align="center" justify="space-between">
        <v-col v-if="hasPrependSlot" cols="12">
          <slot name="prepend" />
        </v-col>
        <v-col class="d-flex align-center">
          <slot name="left" />
        </v-col>
        <v-col v-if="hasRightSlot" cols="auto" class="d-flex align-center">
          <slot name="right" />
        </v-col>
      </v-row>
    </v-card-actions>
  </div>
</template>

<script>
import { VDivider, VRow, VCol, VCardActions } from 'vuetify/lib';

export default {
  name: 'IonosCardActions',
  props: {
    transparentBackground: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VDivider,
    VRow,
    VCol,
    VCardActions,
  },
  computed: {
    hasPrependSlot() {
      return !!this.$slots.prepend;
    },
    hasRightSlot() {
      return !!this.$slots.right;
    },
  },
};
</script>

<style scoped lang="scss"></style>

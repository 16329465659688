<template>
  <v-menu
    :close-on-content-click="false"
    :nudge-top="-44"
    :return-value.sync="internalValue"
    min-width="290px"
    ref="menu"
    transition="scale-transition"
    v-model="menu"
  >
    <v-date-picker
      :locale="locale"
      @input="updateValue()"
      no-title
      ref="picker"
      v-model="internalValue"
      :max="maxDate"
    />
    <template #activator="{ on }">
      <div v-on="on">
        <v-text-field
          @input="updateValue"
          clearable
          readonly
          v-bind="$attrs"
          :value="{ internalValue, locale } | formatDate"
        />
      </div>
    </template>
  </v-menu>
</template>

<script>
import { VMenu, VDatePicker, VTextField } from 'vuetify/lib';

export default {
  name: 'IonosDateInput',
  components: {
    VMenu,
    VDatePicker,
    VTextField,
  },
  props: {
    value: String,
    locale: String,
    birthdayPicker: Boolean,
  },
  data() {
    return {
      menu: false,
      internalValue: this.value,
    };
  },
  methods: {
    updateValue(value) {
      if (value === null) {
        this.$emit('input', null);
        this.$refs.menu.save(null);
      } else {
        this.$emit('input', this.internalValue);
        this.$refs.menu.save(this.internalValue);
      }
    },
  },
  filters: {
    formatDate: function ({ internalValue, locale }) {
      return internalValue
        ? new Intl.DateTimeFormat(locale, {
            dateStyle: 'short',
          }).format(new Date(internalValue))
        : '';
    },
  },
  computed: {
    maxDate() {
      return this.birthdayPicker ? new Date().toISOString().substr(0, 10) : null;
    },
  },
  watch: {
    menu(val) {
      this.birthdayPicker && val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },
};
</script>

<style scoped></style>































import { VFooter, VRow, VCol } from 'vuetify/lib';
import IonosLink from './Link.vue';
import { mixins, Translatable } from '../mixins';

export default mixins(Translatable).extend({
  name: 'IonosFooter',
  components: { VFooter, VRow, VCol, IonosLink },
  data: function () {
    return {
      year: new Date().getFullYear(),
    };
  },
  methods: {
    updateIonosStatus() {
      window.OAO && window.OAO.q.statuspage && window.OAO.q.statuspage.push(['reinit']);
    },
  },
  computed: {
    hasAppendSlot(): boolean {
      return !!this.$slots.append;
    },
  },
  mounted() {
    this.updateIonosStatus();
    window.OAO &&
      window.OAO.q.statuspage &&
      window.OAO.q.statuspage.push(['addOverallStatusListener', () => this.updateIonosStatus()]);
  },
});

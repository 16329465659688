<template>
  <v-dialog
    :max-width="maxWidth"
    transition="slide-y-reverse-transition"
    rounded
    v-model="visible"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <ValidationObserver v-if="visible" v-slot="{ handleSubmit, validated, invalid }">
      <form>
        <v-card>
          <v-card-title class="pr-12">{{ title }}</v-card-title>
          <v-btn v-if="closable" @click="close()" class="close-icon-btn" icon small text>
            <v-icon>close</v-icon>
          </v-btn>
          <slot />
          <slot name="actions" :disabled="validated && invalid" :handleSubmit="handleSubmit" />
          <ionos-card-actions v-if="!hideActions" :transparent-background="transparentActionsBackground">
            <template #prepend>
              <slot name="action-prepend" />
            </template>
            <template #left>
              <ionos-button
                :color="primaryActionColor ? primaryActionColor : 'primary'"
                :primary="primaryActionColor ? null : true"
                @click="handleSubmit(onPrimaryActionClick)"
                :disabled="validated && invalid"
                :loading="loading"
                class="mr-2"
                v-if="primaryActionLabel"
              >
                {{ primaryActionLabel }}
              </ionos-button>
              <ionos-button
                @click="onSecondaryActionClicked"
                secondary
                :disabled="loading"
                v-if="secondaryActionLabel"
                :class="{ 'mr-2': $slots['buttons-append'] }"
              >
                {{ secondaryActionLabel }}
              </ionos-button>
              <slot name="buttons-append" />
            </template>
          </ionos-card-actions>
        </v-card>
      </form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { VDialog, VCard, VCardTitle, VBtn, VIcon } from 'vuetify/lib';
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'IonosFormDialog',
  components: {
    VDialog,
    VCard,
    VCardTitle,
    VBtn,
    VIcon,
    ValidationObserver,
  },
  props: {
    title: String,
    primaryActionLabel: String,
    secondaryActionLabel: String,
    primaryActionColor: String,
    primaryActionFunction: Function,
    hideActions: {
      type: Boolean,
      default: () => false,
    },
    closable: {
      type: Boolean,
      default: () => true,
    },
    large: Boolean,
    small: Boolean,
    transparentActionsBackground: Boolean,
    disableAutomaticClose: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      visible: false,
      data: null,
      loading: false,
    };
  },
  computed: {
    maxWidth() {
      return this.large ? 700 : this.small ? 450 : 600;
    },
  },
  methods: {
    onSecondaryActionClicked() {
      !this.disableAutomaticClose && this.close();
      this.$emit('secondary-action');
    },
    async onPrimaryActionClick() {
      if (this.primaryActionFunction) {
        this.loading = true;
        try {
          await this.primaryActionFunction();
          !this.disableAutomaticClose && this.close();
        } catch (e) {
          this.$emit('error', e);
        } finally {
          this.loading = false;
        }
      } else {
        this.$emit('submit');
      }
      !this.disableAutomaticClose && this.close();
      this.$emit('primary-action', this.data);
    },
    open(data) {
      this.data = data;
      this.visible = true;
      this.$emit('dialog-opened');
    },
    close() {
      this.visible = false;
      this.$emit('dialog-closed');
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin-right: 36px;
}

.close-icon-btn {
  position: absolute;
  top: 16px;
  right: 16px;
}
</style>

<template>
  <div :class="colorClass" style="display: inline-block">
    <a
      v-on="isLinkDisabled ? null : $listeners"
      :class="{
        disabled,
        loading,
        wrap,
        blank,
        'white--text': color === 'white',
        'error--text': color === 'error',
      }"
      :href="isLinkDisabled ? null : href"
      :target="isLinkDisabled ? null : target"
    >
      <span v-if="icon" :class="iconClasses">
        <v-icon :color="color">{{ icon }}</v-icon>
      </span>
      <slot />
      <span v-if="!loading">
        <v-icon v-if="action" dense>arrow_forward</v-icon>
        <v-icon v-else-if="external" size="small" class="d-inline-block vertical-align-unset">open_in_new</v-icon>
      </span>
    </a>
    <v-progress-circular v-if="loading" indeterminate size="12" width="1" />
  </div>
</template>

<script>
import { VProgressCircular, VIcon } from 'vuetify/lib';

export default {
  name: 'IonosLink',
  components: {
    VProgressCircular,
    VIcon,
  },
  props: {
    href: String,
    target: String,
    color: String,
    disabled: Boolean,
    loading: Boolean,
    wrap: Boolean,
    blank: Boolean,
    action: Boolean,
    external: Boolean,
    icon: String,
    iconClasses: String,
  },
  computed: {
    colorClass() {
      return this.color === 'error' ? 'error--text' : 'accent-text';
    },
    isLinkDisabled() {
      return this.disabled || this.loading;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/components/VBtn/_variables.scss';
@import '../assets/sass/variables/colors.scss';

* {
  --underline-width: 2px;
}

@keyframes animate {
  from {
    background-size: 100% var(--underline-width), 0 var(--underline-width);
  }
  to {
    background-size: 0 var(--underline-width), 100% var(--underline-width);
  }
}

.theme--light {
  a {
    color: $light-on-surface-default !important;
  }
}

.theme--dark {
  a {
    color: $dark-on-surface-default !important;
  }
}

a {
  text-decoration: initial;
  cursor: pointer;
  white-space: nowrap;
  display: inline;

  background: none, linear-gradient(to right, $color-secondary-variant, $color-secondary-variant);
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  background-size: 0 var(--underline-width), 100% var(--underline-width);
  line-height: 1.7;

  .v-icon {
    vertical-align: sub;
    font-size: 20px;
  }

  &:hover,
  &:focus {
    animation: animate forwards normal 400ms;
  }

  &:not(.disabled).error--text {
    background-image: none, linear-gradient(to right, var(--v-error-base), var(--v-error-base));
  }

  &.blank {
    background-size: 100% var(--underline-width), 0 var(--underline-width);
    &:hover,
    &:focus {
      background-size: 0 var(--underline-width), 100% var(--underline-width);
    }
  }
}

a.disabled {
  color: rgb(178 178 178) !important;
  cursor: auto;
  pointer-events: none;
  background-image: none, linear-gradient(to right, rgb(178 178 178), rgb(178 178 178));

  &:hover {
    text-decoration: initial;
  }
}

a.loading {
  cursor: auto;

  &:hover {
    text-decoration: initial;
  }
}

a.wrap {
  white-space: normal;
}

.vertical-align-unset {
  vertical-align: unset !important;
}
</style>
